<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end"  >
        <ul class="topbar-menu">
            
            <li class="topbar-search">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    type="text"
                    pInputText
                    placeholder="Pesquisar menus"
                    class="w-12rem sm:w-full"
                    [(ngModel)]="searchText"
                    (input)="filterMenuItems()"
                    (blur)="hideInput()"
                    (focus)="showInput()"
                    (keydown)="handleKeydown($event)" />
                </span>
                <ul class="dropdown-menu" *ngIf="filteredMenuItems.length > 0 && searchText.trim() && isInputVisible" >
                  <li *ngFor="let item of filteredMenuItems; let i = index" [class.active]="i === selectedIndex" >
                    <a (click)="navigateTo(item.menu)" (mousedown)="navigateTo(item.menu)">
                      <i class="{{ item.icon }}"></i>
                      {{ item.nome }}
                    </a>
                  </li>
                </ul>
                <ul class="dropdown-menu" *ngIf="searchText.trim() && filteredMenuItems.length === 0">
                  <li><span>Nenhum menu encontrado</span></li>
                </ul>
            </li>

            <li class="ml-3">
                <button pButton type="button" icon="fa-duotone fa-solid fa-tv" class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" (click)="SlideShow()"></button>
            </li>
              
            <li class="ml-3">
                <button pButton type="button" icon="pi pi-cog" class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" (click)="onConfigButtonClick()"></button>
            </li>

            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <img src="{{imagem}}" alt="Profile" class="profile-image" />
                </button>
            </li>
            <li class="ml-3">
                <button pButton type="button" 
                icon="pi pi-sign-out" 
                class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" 
                (click)="signOut()"></button>
            </li>
        </ul>
    </div>
</div>

<style>
    .profile-image {
        width: 100px; 
        height: 100px;
        border-radius: 50%; 
        object-fit: cover; 
    }

    .topbar-search {
        position: relative;
    }

    .dropdown-menu li.active {
        background-color: #e6e6e6;
        cursor: pointer;
    }

    .topbar-search .dropdown-menu {
        margin-top: 6px;
        z-index: 9999; 
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .dropdown-menu li {
        padding: 10px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        transition: background-color 0.5s ease;
    }

    .dropdown-menu li:hover {
        background-color: #f0f0f0; 
    }

    .dropdown-menu li.active {
        background-color: #e6e6e6;
    }

    .dropdown-menu li:last-child {
        border-bottom: none;
    }

    .dropdown-menu li a {
        text-decoration: none;
        color: #333;
        display: block;
    }

    .dropdown-menu li a:hover {
        background-color: #f0f0f0;
    }

    .topbar-search input {
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    .topbar-search .p-input-icon-left i {
        cursor: pointer;
        transition: color 0.2s ease;
    }
</style>