import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  Router} from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LocalStoreService } from './LocalStoreService/local-store.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import _ from "lodash";
import { StorageService } from './_services/storage.service';
import { AppService } from './app.service';
import { LayoutService, AppConfig } from './layout/service/app.layout.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { retry, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(
    private ls: LocalStoreService,
    private router: Router,
    private storageService: StorageService,
    private appService: AppService,
    private layoutService: LayoutService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const rota = next.routeConfig?.path;
      
    //VERIFICACAO CHAT
    if (next.params['codigo'] == "641762207")
    {
      if (!this.storageService.isLoggedIn())
      {
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.sessionStorage.removeItem('auth-user');
      }
      
      this.storageService.saveOrigem("gap");
      return this.authenticatorChat(rota);
    } else
    {
      if (!this.storageService.isLoggedIn())
      {
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.sessionStorage.removeItem('auth-user');
      }
    }

    //verificacaogap
    if ( (rota?.split('/')[0] == 'gap') && (next.params['codigo'] != "641762207")) 
    {
      return this.appService
      .getLoginGap(next.params['codigo'])
      .then((response) => {

        this.ls.setItem('permissoes',     response.permissoes);
        this.ls.setItem('perfil',         response.perfil);
        this.ls.setItem('agente',         response.agente);
        this.ls.setItem('unidade',        response.unidade); 
        this.ls.setItem('token',          response.token);
        this.ls.setItem('refresh_token',  response.refresh_token);
        this.ls.setItem('gap',           true);
        this.storageService.saveOrigem("gap");

        this.storageService.saveUser(response);

          const config: AppConfig = {
            ripple: true,                     
            inputStyle: 'filled',             
            menuMode: 'overlay',             
            colorScheme: 'light',               
            theme: 'blue',                    
            menuTheme: "colorScheme",           
            scale: 13                           
        };
        this.layoutService.config.set(config);
        this.router.navigate(['/' + rota?.split('/')[2]]);
        return true;
      },
      (error) =>{
        this.router.navigate(['/unauthorized']);
        return false;
      });
    } 

    if(!this.storageService.isLoggedIn())
    {
      this.ls.setItem('rotaRedireciomento', rota);
      this.router.navigate(['/login']);
      return false;
    } else
    {
      const routeName = next.routeConfig?.path;
      if (routeName && this.ls.findMenu(routeName)) 
      {
        return true;
      } else
      {
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }
  }

  authenticatorChat(rota: any){
    this.ls.setItem('chat',   true);

    rota?.split('/')[2] 
    rota = rota?.split('/')[0] + "/" + "641762207" + "/" + rota?.split('/')[2] 
    console.log("rota")

    this.ls.setItem('rota',  rota?.split('/')[2] );

    if(!this.storageService.isLoggedIn())
    {
      this.router.navigate(['/login']);
      return false;
    }

    const routeName = rota?.split('/')[2]
    if (routeName && this.ls.findMenu(routeName)) 
    {
      this.router.navigate(['/' + routeName]);
      return true;
    } else
    {
      this.router.navigate(['/unauthorized']);
      return false;
    }
  }
}

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
    ],
  },
  { path: 'unauthorized', 
  loadChildren: () => 
    import('./pages/accessdenied/accessdenied.module').then(m => m.AccessdeniedModule), 
  },
  {
    path: 'login',
    data: { breadcrumb: 'login' },
    loadChildren: () =>
      import('./pages/auth/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./pages/notfound/notfound.module').then((m) => m.NotfoundModule),
  },
  //
];

const links: any = {
  'consultainss':  () =>
    import('./pages/GInss/consultainss/consultarInss.module').then(
      (m) => m.ConsultarInssModule
    ),
  'inssconfirmada': () =>
    import('./pages/GInss/inssconfirmada/inssconfirmada.module').then(
      (m) => m.InssconfirmadaModule
    ),
  'simulacaoinss': () =>
    import('./pages/GInss/simulacaoinss/simulacaoinss.module').then(
      (m) => m.SimulacaoInssModule
    ),
  'roteiro': () =>
    import('./pages/GInss/roteiro/roteiro.module').then(
      (m) => m.RoteiroModule
    ),
  'saldoextrato': () =>
    import('./pages/GInss/saldoextrato/saldoextrato.module').then(
      (m) => m.SaldoextratoModule
    ),
  'propostas': () =>
    import('./pages/GEsteira/propostas/proposta.module').then(
      (m) => m.PropostaModule
    ),
  'financeiraproposta': () =>
    import('./pages/GFinanceiro/financeiraproposta/financeiraproposta.module').then(
      (m) => m.FinanceiraPropostaModule
    ),
  'unidadenegocios': () =>
    import('./pages/GFinanceiro/unidade/unidadenegocios.module').then(
      (m) => m.UnidadeNegociosModule
    ),
  'unidadenivel': () =>
    import('./pages/GFinanceiro/unidadenivel/unidadenivel.module').then(
      (m) => m.UnidadeNivelModule
    ),
  'esteira': () =>
    import('./pages/GFinanceiro/esteira/esteira.module').then(
      (m) => m.EsteiraModule
    ),
  'banco': () =>
    import('./pages/GFinanceiro/banco/banco.module').then(
      (m) => m.BancoModule
    ),
  'permissao': () =>
    import('./pages/GFinanceiro/permissao/permissao.module').then(
      (m) => m.PermissaoModule
    ),
  'politicadepreco': () =>
    import('./pages/GFinanceiro/politicapreco/unidadepoliticapreco.module').then(
      (m) => m.UnidadePoliticaPrecoModule
    ),
  'tabelacomissao': () =>
    import('./pages/GFinanceiro/tabelacomissao/tabelacomissao.module').then(
      (m) => m.TabelaComissaoModule
    ),
  'tabelacomercial': () =>
    import('./pages/GFinanceiro/tabelacomercial/tabelacomercial.module').then(
      (m) => m.TabelaComercialModule
    ),
  'contacorrente': () =>
    import('./pages/GFinanceiro/contacorrente/contacorrente.module').then(
      (m) => m.ContaCorrenteModule
    ),
  'financeiraunidade': () =>
    import('./pages/GFinanceiro/financeiraunidade/financeiraunidade.module').then(
      (m) => m.FinanceiraUnidadeModule
    ),
  'fechamento': () =>
    import('./pages/GFinanceiro/fechamento/fechamento.module').then(
      (m) => m.FechamentoModule
    ),
  'historicofechamento': () =>
    import('./pages/GFinanceiro/historicofechamento/historicofechamento.module').then(
      (m) => m.HistoricoFechamentoModule
    ),
  'promotora': () =>
    import('./pages/GFinanceiro/promotora/promotora.module').then(
      (m) => m.PromotoraModule
    ),
  'convenio': () =>
    import('./pages/GFinanceiro/convenio/convenio.module').then(
      (m) => m.ConvenioModule
    ),
  'formacontrato': () =>
    import('./pages/GFinanceiro/formacontrato/formacontrato.module').then(
      (m) => m.FormaContratoModule
    ),
  'financeiracia': () =>
    import('./pages/GFinanceiro/financeiracia/financeiracia.module').then(
      (m) => m.FinanceiraModule
    ),
  'spread': () =>
    import('./pages/GFinanceiro/spread/spread.module').then(
      (m) => m.SpreadModule
    ),
  'tabelasfgts': () =>
    import('./pages/GFgts/tabelafgts/tabelafgts.module').then(
      (m) => m.TabelaFgtsModule
    ),
  'consultafgts': () =>
    import('./pages/GFgts/consultafgts/consultafgts.module').then(
      (m) => m.ConsultaFgtsModule
    ),
  'esteirafgts': () =>
    import('./pages/GFgts/esteirafgts/esteirafgts.module').then(
      (m) => m.EsteiraFgtsModule
    ),
  'situacao': () =>
    import('./pages/GFgts/situacao/situacao.module').then(
      (m) => m.SituacaoModule
    ),
  'cofresenha': () =>
    import('./pages/GSuporte/cofresenha/cofresenha.module').then(
      (m) => m.CofreSenhaModule
    ),
  'statusproposta': () =>
    import('./pages/GSuporte/statusproposta/statusproposta.module').then(
      (m) => m.StatusPropostaModule
    ),
  'motivo': () =>
    import('./pages/GSuporte/motivo/motivo.module').then(
      (m) => m.MotivoModule
    ),
  'tipodocumento': () =>
    import('./pages/GSuporte/arquivotipo/arquivotipo.module').then(
      (m) => m.ArquivoTipoModule
    ),
  'usuarios': () =>
    import('./pages/GSuporte/usuarios/usuarios.module').then(
      (m) => m.UsuariosModule
    ),
  'mensagem': () =>
    import('./pages/GSuporte/mensagem/mensagem.module').then(
      (m) => m.MensagemsModule
    ),
  'conta': () =>
    import('./pages/GFinanceiro/conta/conta.module').then(
      (m) => m.ContaModule
    ),
  'formapagamento': () =>
    import('./pages/GFinanceiro/formapagamento/formapagamento.module').then(
      (m) => m.FormaPagamentoModule
    ),
  'categoria': () =>
    import('./pages/GFinanceiro/categoria/categoria.module').then(
      (m) => m.CategoriaModule
    ),
  'saldounidade': () =>
    import('./pages/GFinanceiro/saldounidade/saldounidade.module').then(
      (m) => m.SaldoUnidadeModule
    ),
  'campo': () =>
    import('./pages/GImportacao/campo/campo.module').then(
      (m) => m.CampoModule
    ),
  'layout': () =>
    import('./pages/GImportacao/layout/layout.module').then(
      (m) => m.LayoutModule
    ),
  'bancoapi': () =>
    import('./pages/GImportacao/bancoapi/bancoapi.module').then(
      (m) => m.BancoApiModule
    ),
  'menu': () =>
    import('./pages/GSuporte/menu/menu.module').then(
      (m) => m.MenuModule
    ),
  'statusapi': () =>
    import('./pages/GImportacao/status_api/status_api.module').then(
      (m) => m.StatusApiModule
    ),
  'comissaoapi': () =>
    import('./pages/GImportacao/comissao/comissao.module').then(
      (m) => m.ComissaoModule
    ),
  'financeiraapi': () =>
    import('./pages/GImportacao/financeiracia_api/financeiracia_api.module').then(
      (m) => m.FinanceiraciaApiModule
    ),
  'convenioapi': () =>
    import('./pages/GImportacao/convenio_api/convenio_api.module').then(
      (m) => m.ConvenioApiModule
    ),
  'formacontratoapi': () =>
    import('./pages/GImportacao/formadecontrato_api/formadecontrato_api.module').then(
      (m) => m.FormadecontratoApiModule
    ),
  'finalidadeapi': () =>
    import('./pages/GImportacao/finalidade_api/finalidade_api.module').then(
      (m) => m.FinalidadeApiModule
    ),
  'usuarioapi': () =>
    import('./pages/GImportacao/usuario_api/usuario_api.module').then(
      (m) => m.UsuarioApiModule
    ),
  'motivoapi': () =>
    import('./pages/GImportacao/motivoapi/motivoapi.module').then(
      (m) => m.MotivoApiModule
    ),
  'importacao': () =>
    import('./pages/importacao/importacao.module').then(
      (m) => m.ImportacaoModule
    ),
  'permissoes': () =>
    import('./pages/GSuporte/permissoes/permissoes.module').then(
      (m) => m.PermissoesModule
    ),
  'integracao': () =>
    import('./pages/GSuporte/integracao/integracao.module').then(
      (m) => m.IntegracaoModule
    ),
  'consultabeneficio': () =>
    import('./pages/GInss/consultabeneficio/consultabeneficio.module').then(
      (m) => m.ConsultaBeneficioModule
    ),
  'produto': () =>
    import('./pages/GSuporte/produto/produto.module').then(
      (m) => m.ProdutoModule
    ),
  'configuracao': () =>
    import('./pages/GSuporte/configuracao/configuracao.module').then(
      (m) => m.ConfiguracaoModule
    ),
  'agente': () =>
    import('./pages/GSuporte/agente/agente.module').then(
      (m) => m.AgenteModule
    ),
  'usuariosativo': () =>
    import('./pages/GSuporte/usuariosativo/usuariosativo.module').then(
      (m) => m.UsuariosAtivoModule
    ),
  'sessao': () =>
    import('./pages/GSuporte/sessao/sessao.module').then(
      (m) => m.SessaoModule
    ),
  'citacao': () =>
    import('./pages/GSuporte/citacao/citacao.module').then(
      (m) => m.CitacaoModule
    )
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})

export class AppRoutingModule {
  constructor(private appService: AppService, private router: Router) {
  }

    loadDynamicRoutes(): Promise<void> {
      return new Promise((resolve) => {
        this.appService.getMenus().pipe(
          retry(5),
          catchError(error => {
            return ([]); 
          })).subscribe(menuItems => {
          const dynamicRoutes: any = [];

          menuItems.forEach(menu => {
            if (menu.rota && menu.programa) 
            {
              const link = links[menu.programa];
              if (link)
              {
                const dynamicRoute = {
                  path: menu.rota, 
                  data: { breadcrumb: menu.nome },
                  canActivate: [AuthGuardService],
                  loadChildren: link,
                };
                dynamicRoutes.push(dynamicRoute);
    
                const dynamicRouteGap = {
                  path: ('gap/:codigo/' + menu.rota), 
                  data: { breadcrumb: menu.nome },
                  canActivate: [AuthGuardService],
                  loadChildren: link,
                };
                dynamicRoutes.push(dynamicRouteGap);
              }
            }
          });

          dynamicRoutes.push(
          {
            path: 'perfil',
            loadChildren: () =>
              import('./pages/user/user.module').then((m) => m.UserModule),
          },)
          dynamicRoutes.push(
          {
            path: 'slideshow',
            loadChildren: () =>
              import('./pages/slideshow/slideshow.module').then((m) => m.SlideShowModule),
          },)
  
          const mainRoute: any = [{
            path: '', 
            component: AppLayoutComponent,
            children: dynamicRoutes,
          }];

          const routeDefault: any = [{
            path: '**', 
            redirectTo: '/notfound'
          }];
      
          this.router.resetConfig([...this.router.config, ...mainRoute, ...routeDefault]);
          resolve();
        });
      });
    }
} 